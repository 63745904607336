import React, { useEffect } from "react";

// import Layout from "../../components/layoutAr";
// import Seo from "../../components/seoAr";
// import Landing from "../../components/landing/landingAr";
// import CampaignClosed from "../../components/campaign-closed/campaignClosedAr";
import Layout from "../../components/layoutAr";
import Landing from "../../components/landing/landingAr";
import Seo from "../../components/seoAr";

const IndexPage = (props) => {
  console.log(props);
  useEffect(() => {
    if (props.path.startsWith("/ar")) {
      document.querySelector("body").classList.add("rightToLeft");
    }
  }, []);
  return (
    <Layout>
      <Seo title="الصفحة الأساسية | تأمين سنكرس ضدّ الجوع" lang="ar" />
      {/* Campaign is On */}
      <Landing />
      {/* Campaign Closed */}
      {/* <CampaignClosed /> */}
    </Layout>
  );
};

export default IndexPage;
